// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/icon/drag_indicator_black_24dp.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.left_main_content--fit[data-v-f763bce2] {\n  padding-left: 0px;\n}\n.dmark[data-v-f763bce2] {\n  width: 16px;\n  height: 16px !important;\n  background-size: contain;\n  background-repeat: no-repeat;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.side-nav__item--movetarget[data-v-f763bce2] {\n  border-top: solid 2px rgba(52, 183, 254, 0.5);\n}\n.side-nav__item--movetarget0[data-v-f763bce2] {\n  background: rgba(52, 183, 254, 0.5);\n}\n.publicfoldericon[data-v-f763bce2] {\n  position: absolute;\n  top: 2px;\n  left: -16px;\n  width: 16px;\n  height: 16px;\n}\n.publicfoldericon--exist[data-v-f763bce2] {\n  transform: rotate(90deg);\n}\n\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
