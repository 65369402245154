// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/icon/drag_indicator_black_24dp.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.tr--dragcandidate[data-v-1a8cd098] {\n  border-top: 8px solid rgba(52, 183, 254, 0.5);\n}\n.dragmouse_layer[data-v-1a8cd098] {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  pointer-events: none;\n}\n.dragmouse_title[data-v-1a8cd098] {\n  position: absolute;\n  padding: 8px;\n  color: rgb(38, 128, 235);\n  background: rgba(255, 255, 255, 0.8);\n}\n.dmark[data-v-1a8cd098] {\n  width: 16px;\n  height: 16px !important;\n  background-size: contain;\n  background-repeat: no-repeat;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
